<!--
 * @Description:用户端 查询车辆列表 queryCarList
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 19:17:24
 * @LastEditors: yanxiao
-->
<template>
  <div class='body'>
    <van-nav-bar title="其他车辆查询"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-list class="tablePartBox">
        <van-cell v-for="item in list"
                  :key="item.carId"
                  class="itemBox">
          <van-row>
            <van-col :span="16">
              <van-row class="pla teNumber">
                <span>{{item.carId}}</span>
                <span class="brand">{{item.carType}}</span>
              </van-row>
              <van-row class="findCar"
                       style="">
                <span>{{item.carStatus}}</span>
              </van-row>
            </van-col>
            <van-col :span="8">
              <van-row class="details"
                       @click="handleClickInfo">
                订单详情
                <van-image width="8"
                           height="8"
                           :src="require('../../../assets/homePage/details.png')" />
              </van-row>

            </van-col>
          </van-row>
        </van-cell>
      </van-list>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      list: []// 查询出的列表
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.onLoad()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 顶部返回事件跳转
    handleClickTopBack () {
      this.$router.go(-1)
    },
    // 渲染列表页面
    onLoad () {
      for (let i = 0; i < 1; i++) {
        const info = {
          carId: '渝B' + '36FD' + 'F',
          carType: '蓝牌',
          carStatus: '在停'
        }
        this.list.push(info)
      }
    },
    // 点击查看订单详情
    handleClickInfo () {
      this.$router.push('/parForParkCount')
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    .tablePartBox {
      width: 93.6%;
      height: 96.9px;
      margin: 12.5px 3.6%;
      // padding: 0 12px;
      background-color: #fff;
      border-radius: 5px;
      .itemBox {
        margin-top: 12.5px;
      }
      .plateNumber {
        display: flex;
        // justify-content: center;
        font-size: 25px;
        color: #333333;
        font-weight: 500;
        margin-top: 19.9px;
        margin-left: 12px;
        .brand {
          display: block;
          margin: 3px 0 5px 12.5px;
          color: #fff;
          background: #597fc5;
          border-radius: 5px;
          font-size: 13px;
          line-height: 17px;
          width: 34px;
          height: 17px;
          text-align: center;
        }
      }
      .findCar {
        margin-left: 12px;
        color: #333333;
        font-size: 15px;
        font-weight: 400;
        margin-top: 8.5px;
      }
      .details {
        margin-top: 62px;
        // margin-right: 19px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
}
</style>
